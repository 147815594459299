<template>
	<ion-page>
		<spot-header :title="selectLocationText" :canGoHome="false" :canGoBack="true" @pressedGoHome="$router.push('/home')" @pressedGoBack="exitSelection"></spot-header><!--title:"selectDocumentText"-->

		<ion-content :fullscreen="true"> <!--v-if="isActive"-->
			<ion-progress-bar type="indeterminate" v-if="!isActive"></ion-progress-bar>
			<div id="actionArea" v-else>
				<web-socket-reader crReplacedChar="|" @readedBarcode="onReadedBarcode" @readedTag="onReadedTag" @readerError="onReaderError"></web-socket-reader>
				<ion-item lines="none" class="no-pad">
					<spot-input
						:ref="fields[field.search].name"
						:label="fields[field.search].text"
						:allignLabel="fields[field.search].allignLabel"
						:required="fields[field.search].required"
						:placeholder="fields[field.search].placeholder"
						:enabled="true"
						:value="fields[field.search].value"
						:type="fields[field.search].inputType"
						:clearInput="true"
						:canRefresh="fields[field.search].canRefresh"
						@refresh="this.$router.push('/inventory/selection/' + projectId + '?free=' + isFree + '&picking=' + isPicking)"
						@textChanged="changedSearch">
					</spot-input>
					<ion-button size="default" slot="end" disabled="false" @click="createNewDocument">
						<ion-icon :icon="duplicate" slot="start" size="default"></ion-icon>
						{{ stringAddNew }}
					</ion-button>
				</ion-item>
				<ion-progress-bar type="indeterminate" v-if="!tablesData.documents"></ion-progress-bar>
				<EasyDataTable
					:headers="headers"
					:items="tablesData.documents"
					:sort-by="sortBy"
					:sort-type="sortType"
					:search-field="searchFields"
					:search-value="fields[field.search].value"
					:empty-message="emptyLocation"
					:body-row-class-name="bodyRowClassNameFunction"
					@click-row="selectedExistingDocument"
					multi-sort
				><!-- :empty-message="emptyDocument" :body-item-class-name="bodyItemClassNameFunction" :body-expand-row-class-name="bodyExpandRowClassNameFunction" show-index alternating -->
					<template #item-actions="document">
						<!--
						<ion-button :color="document.closed ? 'danger' : document.partial ? 'warning' : 'success'" :disabled="document.closed" fill="outline" shape="round" size="small" slot="icon-only" @click="selectedExistingDocument(document)">
							<ion-icon :icon="enter" slot="end" size="small"></ion-icon>
						</ion-button>
						-->
						<ion-button disabled=false color="dark" fill="outline" shape="round" size="small" slot="icon-only" @click="showExistingDocument(document)">
							<ion-icon :icon="reader" slot="end" size="small"></ion-icon>
						</ion-button>
						<!--
						<ion-button disabled=true fill="outline" shape="round" size="small" slot="icon-only" @click="editExistingDocument(document)">
							<ion-icon :icon="brush" slot="end" size="small"></ion-icon>
						</ion-button>
						<ion-button disabled=true fill="outline" shape="round" size="small" slot="icon-only" @click="deleteExistingDocument(document)">
							<ion-icon :icon="trash" slot="end" size="small"></ion-icon>
						</ion-button>
						-->
					</template>
				</EasyDataTable>
				<ion-modal ref="modal" backdrop-dismiss="false" :is-open="isOpenModal" @didDismiss="modalDismissed">
					<ion-content>
						<ion-toolbar>
							<ion-title> {{ modalTitle }}</ion-title>
						</ion-toolbar>
						<div v-for="field in documentContents" :key="field.id">
							<spot-input
								v-if="(field.inputType == 'text' || field.inputType == 'number') && field.visible && !!field.value"
								:ref="field.name"
								:label="field.text"
								:allignLabel="field.allignLabel"
								:required="field.required"
								:placeholder="getPlaceHolder(field)"
								:enabled="field.enabled && !modalReadOnly"
								:validationState="field.validationState"
								:value="field.value"
								:type="field.inputType"
								@textChanged="changedTextField(field.id, $event)">
							</spot-input>
							<ion-textarea
								v-if="field.inputType == 'area' && field.visible && !!field.value"
								:ref="field.name"
								:required="field.required"
								:placeholder="getPlaceHolder(field)"
								:disabled="!field.enabled || modalReadOnly"
								:auto-grow="true"
								:value="field.value">
							</ion-textarea>
							<spot-checkbox
								v-if="field.inputType == 'check' && field.visible"
								:ref="field.name"
								:label="field.text"
								:allignLabel="field.allignLabel"
								:required="field.required"
								:enabled="field.enabled && !modalReadOnly"
								:validationState="field.validationState"
								:value="field.value"
								@checkboxChanged="changedCheckboxField(field.id, $event)">
							</spot-checkbox>
							<spot-select
								v-if="field.inputType == 'select' && field.visible"
								:ref="field.name"
								:label="field.text"
								:allignLabel="field.allignLabel"
								:required="field.required"
								:placeholder="getPlaceHolder(field)"
								:enabled="field.enabled && !modalReadOnly"
								:enabledExtraButtons="field.enabledExtraButtons && !modalReadOnly"
								:validationState="field.validationState"
								:value="field.value"
								:options="getOptions(field.id)"
								:optionField="field.refField"
								:canAddItem="field.canAddItem"
								:canRefreshList="field.canRefresh"
								:canUnlock="field.canUnlock"
								:selectedLabel="selectedText"
								:deselectLabel="deselectText"
								:selectLabel="selectText"
								@selected="assignedSelectField(field.id, $event)"
								@unselected="resetSelectField(field.id)">
							</spot-select>
						</div>
						<div class="flex-row-center-container">
							<spot-button
								fill="outline"
								size="small"
								color="primary"
								:enabled="true"
								:expand="null"
								:text="undoText"
								@clicked="closeModal(false)">
							</spot-button>
							<spot-button
								fill="outline"
								size="small"
								color="primary"
								:enabled="!isEmptyRequiredFields && modalReadOnly == false"
								:expand="null"
								:text="confirmText"
								@clicked="closeModal(true)">
							</spot-button>
						</div>
					</ion-content>
				</ion-modal>
			</div>
			<div id="debugArea" style="color:red;" v-if="settings.debug">
				<div>Test Area</div>
				<spot-button
					fill="outline"
					size="large"
					color="primary"
					:enabled="true"
					text="Simulate Scan"
					@clicked="onReadedBarcode('A','barcode')">
				</spot-button>
				<div v-for="field in fields" :key="field.id">
					{{ field.name }} = {{ field.value }}
				</div>
			</div>
		</ion-content>

		<spot-footer></spot-footer>
	</ion-page>
</template>

<style scoped>
	ion-modal {
		--height: 80%;
		--width: 90%;
		--border-radius: 16px;
		--box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	}
	ion-modal::part(backdrop) {
		background: var(--ion-color-medium);
		opacity: 1;
	}
	ion-modal ion-toolbar {
		--background: var(--ion-color-primary);
		--color: white;
	}
	.flex-row-center-container {
		margin-top: 0.2em;
		text-align: center;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	.no-pad {
		margin-top: 0px;
		margin-bottom: 0px;
		--padding-top: 0px;
		--padding-bottom: 0px;
		--inner-padding-top: 0px;
		--inner-padding-bottom: 0px;
		--min-height: 0px
	}
	*:deep(ion-checkbox) {
		margin-top: 0px;
		margin-bottom: 0px;
	}
</style>
<style>
	.closed-row {
		--easy-table-body-row-background-color: var(--ion-color-danger);
	}
	.partial-row {
		--easy-table-body-row-background-color: var(--ion-color-warning);
	}
	.new-row {
		/* --easy-table-body-row-background-color: var(--ion-color-success);*/
		--easy-table-body-row-background-color: var(--ion-color-light);
	}
	.expanded-row {
		--easy-table-body-row-background-color: var(--ion-color-medium);
	}
	.prominence-column {
		font-size: 20px;
	}
</style>

<script>
import { IonButton, IonContent, IonIcon, IonItem, IonModal, IonPage, IonProgressBar, IonTextarea, IonTitle, IonToolbar } from '@ionic/vue';
import { brush, duplicate, enter, reader, trash } from 'ionicons/icons';
import { defineComponent } from 'vue';
import SpotButton from '../components/SpotButton.vue';
import SpotCheckbox from '../components/SpotCheckbox.vue';
import SpotInput from '../components/SpotInput.vue';
import SpotSelect from '../components/SpotSelect.vue';
import SpotFooter from "../components/SpotFooter.vue";
import SpotHeader from "../components/SpotHeader.vue";
import WebSocketReader from '../components/WebSocketReader.vue';
import { /* checkUserSession, */ confirmYesNo /*, insertValue*/, settings, showMsgError, showMsgInfo, showMsgWarning, writeLog } from '../plugins/common.js';
import { createDocument, deleteDocument, getLocations, getProject, searchDocument, searchDocuments, searchLocation, tablesData, updateDocument } from '../plugins/customDatabase.js';

	export default defineComponent({
		name: "SelectInventoryDocument",
		props: {
			pageTitle: { type: String, default: 'Seleziona Documento di Inventario' },
			selectDocumentText: { type: String, default: 'Seleziona Documento' },
			selectLocationText: { type: String, default: 'Seleziona Ubicazione' },
			manageText: { type: String, default: 'Gestisci ' },
			emptyDocument: { type: String, default: 'Nessun Documento presente' },
			emptyLocation: { type: String, default: 'Nessuna Ubicazione presente' },
			stringAddNew: { type: String, default: 'Agggiungi' },
			stringNewDocument: { type: String, default: 'Nuovo Documento' },
			stringNewLocation: { type: String, default: 'Nuova Ubicazione' },
			stringChangeDocument: { type: String, default: 'Modifica Documento' },
			stringChangeLocation: { type: String, default: 'Modifica Ubicazione' },
			stringShowDocument: { type: String, default: 'Visualizza Documento' },
			stringShowLocation: { type: String, default: 'Visualizza Ubicazione' },
			stringSearchDocument: { type: String, default: 'Ricerca Documento' },
			stringSearchLocation: { type: String, default: 'Ricerca Ubicazione' },
			stringNotFoundDocument: { type: String, default: 'Documento non trovato!' },
			stringNotFoundLocation: { type: String, default: 'Ubicazione non trovata!' },
			stringFoundExistedDocument: { type: String, default: 'Documento già presente in ' },
			stringFoundExistedLocation: { type: String, default: 'Ubicazione già presente in ' },
			stringManageDocument: { type: String, default: 'Gestisci Documento' },
			stringManageLocation: { type: String, default: 'Gestisci Ubicazione' },
			stringFoundClosedDocument: { type: String, default: 'Documento non accessibile. E\' già stata dichiarato concluso in ' },
			stringFoundClosedLocation: { type: String, default: 'Ubicazione non accessibile. E\' già stata dichiarata conclusa in ' },
			stringDeletehDocument: { type: String, default: 'Cancella Documento' },
			stringDeleteLocation: { type: String, default: 'Cancella Ubicazione' },
			confirmDeleteDocument: { type: String, default: 'Confermi la cancellazione del Documento?' },
			confirmDeleteLocation: { type: String, default: 'Confermi la cancellazione dell\'Ubicazione?' },
			selectedText: { type: String, default: 'Selezionato' },
			deselectText: { type: String, default: 'annulla' },
			selectText: { type: String, default: 'seleziona' },
			undoText: { type: String, default: 'Annulla' },
			confirmText: { type: String, default: 'Conferma' },
			unselected: { type: String, default: 'Nessuno' },
			checkTagText: { type: String, default: 'Controllo TAG' },
			warnMultipleTAG: { type: String, default: 'Letto più di un TAG. Avvicinarsi solo al TAG da leggere e riprovare' },
			warnInavlidTAG: { type: String, default: 'TAG letto solo in parte. Riprovare a leggere' },
			messageTitleReader: { type: String, default: 'Lettura Barcode/TAG' },
			errorReadingReader: { type: String, default: 'Errore nella lettura del barcode/tag' },
		},
		components: {
			IonPage,
			IonContent,
			IonProgressBar,
			IonItem,
			IonButton,
			IonIcon,
			IonModal,
			IonToolbar,
			IonTitle,
			IonTextarea,
			SpotHeader,
			SpotFooter,
			SpotInput,
			SpotCheckbox,
			SpotSelect,
			SpotButton,
			WebSocketReader,
		},
		data: () => ({
			isActive: null,
			field: {
				search: 0,
			},
			fields: [
				{ id: 0, name: 'search', text: 'Cerca: ', allignLabel: true, placeholder: 'Cerca Ubicazione', inputType: 'text', defaultValue: '', value: null, required: false, refField: null, canRefresh: true },
			],
			searchFields: ['name', 'customer.name', 'supplier.name'],
			headers: [
				// { text: 'Documento', value: 'name', sortable: true },
				{ text: 'Ubicazione', value: 'name', sortable: true },
				{ text: 'Terminato', value: 'closed', sortable: true },
				{ text: 'Parziale', value: 'partial', sortable: true },
				{ text: 'Azioni', value: 'actions', sortable: false }
			],
			sortType: ['asc', 'desc', 'asc'],
			sortBy: ['closed', 'partial', 'name'],
			documentContent: {
				id: 0,
				name: 1,
				code: 2,
				description: 3,
				issuedAt: 4,
				closed: 5,
				partial: 6,
				quantityDetected: 7,
			},
			documentContents: [
				{ id: 0, name: 'id', text: 'Id', placeholder: 'Inserisci ID', inputType: "number", enabled: false, visible: false, allignLabel: true, validationState: null, defaultValue: null, value: null, required: true },
				//{ id: 1, name: 'name', text: 'Documento', placeholder: 'Inserisci Documento', inputType: "text", enabled: false, visible: true, allignLabel: true, validationState: null, defaultValue: null, value: null, required: true },
				{ id: 1, name: 'name', text: 'Ubicazione', placeholder: 'Inserisci Ubicazione', inputType: "select", enabled: true, enabledExtraButtons: false, visible: true, allignLabel: true, validationState: null, defaultValue: null, value: null, required: true, refField: 'name', canAddItem: false, canRefresh: false, canUnlock: false },
				{ id: 2, name: 'code', text: 'Codice', placeholder: 'Inserisci Codice', inputType: "text", enabled: false, visible: true, allignLabel: false, validationState: null, defaultValue: null, value: null, required: false },
				{ id: 3, name: 'description', text: 'Descrizione', placeholder: 'Inserisci Descrizione', inputType: "text", enabled: false, visible: true, allignLabel: false, validationState: null, defaultValue: null, value: null, required: false },
				{ id: 4, name: 'issuedAt', text: 'Data', placeholder: 'Inserisci Data', inputType: "text", enabled: false, visible: true, allignLabel: true, validationState: null, defaultValue: null, value: null, required: true },
				{ id: 5, name: 'closed', text: 'Chiuso', inputType: "check", enabled: false, visible: true, allignLabel: true, validationState: null, defaultValue: false, value: null, required: false },
				{ id: 6, name: 'partial', text: 'Parziale', inputType: "check", enabled: false, visible: true, allignLabel: true, validationState: null, defaultValue: false, value: null, required: false },
				{ id: 7, name: 'quantityDetected', text: 'Nr. Articoli', placeholder: 'Inserisci Nr. Articoli', inputType: "number", enabled: false, visible: true, allignLabel: true, validationState: null, defaultValue: null, value: null, required: false },
			],
			isOpenModal: false,
			modalTitle: null,
			modalReadOnly: null,
			actionDbCreate: null,
			isFree: null,
			isPicking: null,
			projectId: null,
			lastBarcode: null,
			lastTag: null,
			duplicate,
			enter,
			reader,
			trash,
			brush,
			settings,
			confirmYesNo,
			showMsgError,
			showMsgInfo,
			showMsgWarning,
			writeLog,
			createDocument,
			deleteDocument,
			getLocations,
			getProject,
			searchDocument,
			searchDocuments,
			searchLocation,
			updateDocument,
			tablesData,
		}),
		async ionViewWillEnter() {
			this.getUrlParamsAndQuery();
			this.setDefault();
			await this.getAllTablesData();
			this.isActive = true;
		},
		ionViewWillLeave() {
			this.isOpenModal = false;
			this.isActive=false;
		},
		computed: {
			isEmptyRequiredFields() {
				let ret = false;
				this.documentContents.forEach(element => {if (element.required && !element.value && element.id > 0) ret=true});
				return ret;
			},
			stringPageTitle() {
				if (!tablesData || !tablesData.currentProject) return this.pageTitle;
				// else return this.selectDocumentText;
				// else return this.selectLocationText;
				else return this.manageText + tablesData.currentProject.name
			},
		},
		setup() {
			const bodyRowClassNameFunction = (element) => {
				if (element.closed == true || element.prepared == true || element.delivered == true ) return 'closed-row'
				else if(element.partial == true) return 'partial-row'
				else return 'new-row';
			}
			const bodyItemClassNameFunction = (column) => {
				if (column === 'name') return 'prominence-column';
				else return '';
			};
			const bodyExpandRowClassNameFunction = () => {
				return 'expanded-row';
			};
			return {
				bodyRowClassNameFunction,
				bodyItemClassNameFunction,
				bodyExpandRowClassNameFunction,
			};
		},
		methods: {
			// Settings & StartUp
			getUrlParamsAndQuery() {
				this.isFree = (/true/i).test(this.$route.query.free);
				this.isPicking = (/true/i).test(this.$route.query.picking);
				this.projectId = this.$route.params.id;
			},
			setDefault() {
				this.fields.forEach(element => element.value = element.defaultValue);
				this.isOpenModal = false;
				this.modalTitle = null;
				this.modalReadOnly = null;
				this.actionDbCreate = null;
				this.lastBarcode = null;
				this.lastTag = null;
			},
			getPlaceHolder(element) {
				return ((this.settings.showFieldsPlaceholder) ? (element.required ? element.placeholder : this.unselected ) : '')
			},
			getOptions(elementId) {
				let myOption = null;
				switch (elementId) {
					case this.documentContent.name:
						myOption = this.tablesData.locations;
						break
					default:
						//Do Nothing
						break
				}
				return myOption;
			},
			// Database
			async getAllTablesData() {
				await this.getProject(this.projectId);
				this.tablesData.documents = [];
				await this.searchDocuments(this.projectId);
				this.tablesData.locations = [];
				await this.getLocations();
			},
			async deleteExistingDocument(document) {
				let canDelete = false;
				await this.confirmYesNo(/*this.stringDeleteDocument*/this.stringDeleteLocation, /*this.confirmDeleteDocument*/this.confirmDeleteLocation)
					.then((data) => { canDelete = data })
				if (canDelete == true) {
					await this.deleteDocument(document.id)
				}
			},
			// Actions
			async onReadedBarcode(barcode,type) {
				this.lastBarcode = barcode;
				this.writeLog(barcode, type);
				await this.committedBarcode();
			},
			async onReadedTag(tag,type) {
				this.lastTag = tag;
				this.writeLog(tag, type);
				await this.committedTag();
			},
			onReaderError(data,error) {
				this.showMsgError(data, error);
				this.showMsgWarning(this.messageTitleReader, this.errorReadingReader);
			},
			changedSearch(value) {
				this.fields[this.field.search].value = value;
			},
			changedTextField(fieldId, value) {
				this.documentContents[fieldId].value = value;
				(!value || value.length == 0)? this.documentContents[fieldId].validationState = false : this.documentContents[fieldId].validationState = true
			},
			changedCheckboxField(fieldId, value) {
				this.documentContents[fieldId].value = value;
				(!value || value.length == 0)? this.documentContents[fieldId].validationState = false : this.documentContents[fieldId].validationState = true
			},
			assignedSelectField(fieldId, value) {
				this.documentContents[fieldId].value = value;
				(!value)? this.documentContents[fieldId].validationState = false : this.documentContents[fieldId].validationState = true
			},
			resetSelectField(fieldId) {
				this.documentContents[fieldId].value = null;
				this.documentContents[fieldId].validationState = false
			},
			prepareNewDocument() {
				let myRecord = {
					document: {
						name: this.documentContents[this.documentContent.name].value.name,
						code: this.documentContents[this.documentContent.code].value,
						description: this.documentContents[this.documentContent.description].value,
						issued_at: this.documentContents[this.documentContent.issuedAt].value,
						closed: this.documentContents[this.documentContent.closed].value,
						partial: this.documentContents[this.documentContent.partial].value,
						project_id: this.projectId,
					}
				};
				return myRecord;
			},
			prepareUpdateDocument() {
				let myRecord = {
					project: {
						name: this.documentContents[this.documentContent.name].value.name,
						code: this.documentContents[this.documentContent.code].value,
						description: this.documentContents[this.documentContent.description].value,
						issued_at: this.documentContents[this.documentContent.issuedAt].value,
						project_id: this.projectId,
					}
				};
				return myRecord;
			},
			updateDocumentContents(row) {
				Object.getOwnPropertyNames(row).forEach(rowField => {
					this.documentContents.forEach(pageElement => {
						if (rowField == pageElement.name) {
							pageElement.value = row[rowField];
						}
					});
				});
			},
			createCustomDocumentContents() {
				this.documentContents[this.documentContent.issuedAt].value = new Date().toLocaleDateString()
			},
			async updateCustomDocumentContents(row) {
				await this.searchLocation(row.name)
				this.documentContents[this.documentContent.id].value = row.id
				this.documentContents[this.documentContent.name].value = this.tablesData.currentLocation
				this.documentContents[this.documentContent.code].value = row.code
				this.documentContents[this.documentContent.description].value = row.description
				this.documentContents[this.documentContent.issuedAt].value = (!row.issued_at ? null : new Date(row.issued_at).toLocaleDateString())
				this.documentContents[this.documentContent.quantityDetected].value = row.quantity_detected
				this.documentContents[this.documentContent.closed].value = row.closed
				this.documentContents[this.documentContent.partial].value = row.partial
			},
			createNewDocument() {
				this.documentContents.forEach(element => element.value = element.defaultValue);
				this.createCustomDocumentContents();
				this.documentContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.stringNewLocation; //this.stringNewDocument;
				this.actionDbCreate = true;
				this.openModal(false);
			},
			async editExistingDocument(document) {
				await this.updateCustomDocumentContents(document);
				this.documentContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.stringChangeLocation //this.stringChangeDocument;
				this.actionDbCreate = false;
				this.openModal(false);
			},
			async showExistingDocument(document) {
				this.updateCustomDocumentContents(document);
				this.documentContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.stringShowLocation //this.stringShowDocument;
				this.actionDbCreate = false;
				this.openModal(true);
			},
			async selectedExistingDocument(document) {
				if (!this.isOpenModal) {
					if (document.closed == false) {
						this.$router.push("/inventory/mixed_management/" + this.projectId + "?document=" + document.id + "&free=" + this.isFree + "&picking=" + this.isPicking)
					}
					else {
						this.showMsgWarning(/*this.stringManageDocument*/this.stringManageLocation, /*this.stringFoundClosedDocument*/this.stringFoundClosedLocation + this.tablesData.currentProject.name);
					}
				}
			},
			openModal(isReadOnly) {
				this.modalReadOnly = isReadOnly;
				this.isOpenModal = true;
			},
			async closeModal(saveRecord) {
				let canCloseModal = true;

				if (saveRecord == true) {
					if (this.actionDbCreate == true) {
						this.tablesData.currentDocument = null;
						await this.searchDocument(this.projectId, this.documentContents[this.documentContent.name].value.name)
						if (!this.tablesData.currentDocument) {
							await this.createDocument(this.prepareNewDocument())
						}
						else {
							this.showMsgWarning(/*this.stringSearchDocument*/this.stringSearchLocation, /*this.stringFoundExistedDocument*/this.stringFoundExistedLocation + this.tablesData.currentProject.name);
							canCloseModal = false;
						}
					}
					else {
						await this.updateDocument(this.documentContents[this.documentContent.id].value, this.prepareUpdateDocument())
					}
				}
				if (canCloseModal) {
					await this.getAllTablesData();
				this.isOpenModal = false;
				}
			},
			async modalDismissed() {
				this.actionDbCreate == null;
			},
			changedBarcode(value) {
				this.lastBarcode = value;
			},
			async committedBarcode() {
				let foundElement = null;
				this.tablesData.documents.some(document => {
					if (document.name == this.lastBarcode) {
						foundElement = document;
						return true;
					}
				});
				if (!foundElement) this.showMsgWarning(/*this.stringSearchDocument*/this.stringSearchLocation, /*this.stringNotFoundDocument*/this.stringNotFoundLocation);
				else this.selectedExistingDocument(foundElement);
			},
			changedTag(value) {
				this.lastTag = value;
			},
			async committedTag() {
				let tagElements = this.lastTag.split('|');
				let foundElement = null;

				switch (tagElements.length) {
					case 0:
						this.lastTag = null;
						this.showMsgWarning(this.checkTagText,this.warnInavlidTAG);
						break;
					case 1:
						this.tablesData.documents.some(document => {
							if (document.name == this.lastTag) {
								foundElement = document;
								return true;
							}
						});
						if (!foundElement) this.showMsgWarning(/*stringSearchDocument*/this.stringSearchLocation, /*this.stringNotFoundDocument*/this.stringNotFoundLocation);
						else this.selectedExistingDocument(foundElement);
						break;
					default: // in futuro si può implementare la gestione di tag multipli per selezionare più ordini
						this.lastTag = null;
						this.showMsgWarning(this.checkTagText,this.warnMultipleTAG);
						break;
				}
			},
			async exitSelection() {
				let canExit = !this.isOpenModal;
				
				if (canExit == true) {
					this.$router.push("/inventory/selection" + "?free=" + this.isFree + "&picking=" + this.isPicking);
				}
			}
		}
	});
</script>
<template>
 <ion-page>
		<spot-header :title="pageTitle + ' ' + (!tablesData || !tablesData.currentProject ? '' : tablesData.currentProject.name)" :canGoHome="false" :canGoBack="false" @pressedGoHome="$router.push('/home')" @pressedGoBack="exitSupply"></spot-header>

		<ion-content :fullscreen="true"> <!--v-if="isActive"-->
			<ion-progress-bar type="indeterminate" v-if="!isActive"></ion-progress-bar>
			<div id="actionArea" v-else>
				<web-socket-reader crReplacedChar="|" @readedBarcode="onReadedBarcode" @readedTag="onReadedTag" @readerError="onReaderError"></web-socket-reader>
				<div class="flex-row-center-container">
					<spot-button
						fill="outline"
						size="small"
						color="danger"
						:enabled="true"
						:expand="null"
						:text="suspendText"
						@clicked="exitSupply()">
					</spot-button>
					<spot-button
						fill="outline"
						size="small"
						color="success"
						:enabled="true"
						:expand="null"
						:text="closeText"
						@clicked="closeSupply()">
					</spot-button>
				</div>
				<ion-item>
					<spot-input
						:ref="fields[field.search].name"
						:label="fields[field.search].text"
						:allignLabel="fields[field.search].allignLabel"
						:required="fields[field.search].required"
						:placeholder="fields[field.search].placeholder"
						:enabled="true"
						:value="fields[field.search].value"
						:type="fields[field.search].inputType"
						:clearInput="true"
						@textChanged="changedSearch">
					</spot-input>
					<ion-button v-if="!isPicking" size="default" slot="end" disabled="false" @click="createNewPick(null)">
						<ion-icon :icon="duplicate" slot="start" size="default"></ion-icon>
						{{ newItemText }}
					</ion-button>
				</ion-item>
				<ion-progress-bar type="indeterminate" v-if="!tablesData.picks"></ion-progress-bar> <!-- v-if="!tablesData.projectRows" -->
				<EasyDataTable
					:headers="headers"
					:items="tablesData.picks"
					:sort-by="sortBy"
					:sort-type="sortType"
					:search-field="searchFields"
					:search-value="fields[field.search].value"
					:empty-message="noItemsText"
					:body-row-class-name="bodyRowClassNameFunction"
					@click-row="tableItemClicked"
					multi-sort> <!-- :items="tablesData.projectRows" :body-item-class-name="bodyItemClassNameFunction" show-index alternating @click-row="createNewProjectRow" -->
					<template #item-actions="pick">
						<!--
						<ion-button disabled=false fill="outline" shape="round" size="small" slot="icon-only" @click="showExistingPick(pick)">
							<ion-icon :icon="reader" slot="end" size="small"></ion-icon>
						</ion-button>
						<ion-button :color="pick.quantity_detected == pick.quantity ? 'success' : (pick.quantity_detected == 0 ? 'danger' : (pick.quantity_detected < pick.quantity ? 'warning' : 'tertiary'))" disabled=false fill="outline" shape="round" size="small" slot="icon-only" @click="editExistingPick(pick)">
							<ion-icon :icon="brush" slot="end" size="small"></ion-icon>
						</ion-button>
						-->
						<ion-button :disabled="pick.project_rows.length == 0" color="dark" fill="outline" shape="round" size="small" slot="icon-only" @click="openAlert(pick)"> <!-- @click="deleteProjectRowsInPick(pick)" @click="deleteExistingPick(pick)" -->
							<ion-icon :icon="trash" slot="end" size="small"></ion-icon>
						</ion-button>
					</template>
					<!--
					<template #item-actions="projectRow">
						<ion-button disabled=false fill="outline" shape="round" size="small" slot="icon-only" @click="showExistingProjectRow(projectRow)">
							<ion-icon :icon="reader" slot="end" size="small"></ion-icon>
						</ion-button>
						<ion-button :color="pick.quantity_detected == pick.quantity ? 'success' : (pick.quantity_detected == 0 ? 'danger' : (pick.quantity_detected < pick.quantity ? 'warning' : 'tertiary'))" disabled=false fill="outline" shape="round" size="small" slot="icon-only" @click="editExistingProjectRow(projectRow)">
							<ion-icon :icon="brush" slot="end" size="small"></ion-icon>
						</ion-button>
						<ion-button disabled=true fill="outline" shape="round" size="small" slot="icon-only" @click="deleteExistingProjectRow(projectRow)">
							<ion-icon :icon="trash" slot="end" size="small"></ion-icon>
						</ion-button>
					</template>
					-->
					<template #expand="item">
						{{item.quantity}} {{item.item.measure_unit.name}} | Note {{(!item.notes)? '-': item.notes}}
					</template>
				</EasyDataTable>
				<ion-modal class="modal-settings" ref="modal" backdrop-dismiss="false" :is-open="isOpenModal" @didDismiss="modalDismissed">
					<ion-content>
						<ion-toolbar>
							<ion-title> {{ modalTitle }}</ion-title>
						</ion-toolbar>
						<div v-for="field in itemContents" :key="field.id">
							<spot-input
								v-if="(field.inputType == 'text' || field.inputType == 'number')"
								:ref="field.name"
								:label="field.text"
								:allignLabel="field.allignLabel"
								:required="field.required"
								:placeholder="getPlaceHolder(field)"
								:enabled="getEnabled(field, field.enabled)"
								:validationState="field.validationState"
								:furtherInfo="field.furtherInfo"
								:value="field.value"
								:type="field.inputType"
								@textChanged="changedTextField(field.id, $event)">
							</spot-input>
							<ion-textarea
								v-if="field.inputType == 'area'"
								:ref="field.name"
								:placeholder="getPlaceHolder(field)"
								:required="field.required"
								:disabled="!getEnabled(field, field.enabled)"
								:auto-grow="true"
								:value="field.value">
							</ion-textarea>
							<spot-checkbox
								v-if="field.inputType == 'check'"
								:ref="field.name"
								:label="field.text"
								:allignLabel="field.allignLabel"
								:required="field.required"
								:enabled="getEnabled(field, field.enabled)"
								:validationState="field.validationState"
								:value="field.value"
								@checkboxChanged="changedCheckboxField(field.id, $event)"
								@focus="$event.target.select()">
							</spot-checkbox>
							<spot-select
								v-if="field.inputType == 'select'"
								:ref="field.name"
								:label="field.text"
								:allignLabel="field.allignLabel"
								:required="field.required"
								:placeholder="getPlaceHolder(field)"
								:enabled="getEnabled(field, field.enabled)"
								:enabledExtraButtons="getEnabled(field, field.enabledExtraButtons)"
								:validationState="field.validationState"
								:value="field.value"
								:options="getOptions(field)"
								:optionField="field.refField"
								:canAddItem="field.canAddItem"
								:canRefreshList="field.canRefresh"
								:canUnlock="field.canUnlock"
								:selectedLabel="selectedText"
								:deselectLabel="deselectText"
								:selectLabel="selectText"
								@selected="assignedSelectField(field.id, $event)"
								@unselected="resetSelectField(field.id)">
							</spot-select>
							<spot-select-custom
								v-if="field.inputType == 'combo'"
								:ref="field.name"
								:label="field.text"
								:allignLabel="field.allignLabel"
								:required="field.required"
								:placeholder="getPlaceHolder(field)"
								:enabled="getEnabled(field, field.enabled)"
								:enabledExtraButtons="getEnabled(field, field.enabledExtraButtons)"
								:validationState="field.validationState"
								:value="field.value"
								:options="getOptions(field)"
								:optionField="field.refField"
								:canAddItem="field.canAddItem"
								:canRefreshList="field.canRefresh"
								:canUnlock="field.canUnlock"
								:selectedLabel="selectedText"
								:deselectLabel="deselectText"
								:selectLabel="selectText"
								:custom-label="customItem"
								@selected="assignedSelectField(field.id, $event)"
								@unselected="resetSelectField(field.id)">
							</spot-select-custom>
						</div>
						<div class="flex-row-center-container">
							<spot-button
								fill="outline"
								size="large"
								color="primary"
								:enabled="true"
								:expand="null"
								:text="undoText"
								@clicked="closeModal(false)">
							</spot-button>
							<spot-button
								fill="outline"
								size="large"
								color="primary"
								:enabled="!isEmptyRequiredFields && modalReadOnly == false"
								:expand="null"
								:text="confirmText"
								@clicked="closeModal(true)">
							</spot-button>
						</div>
					</ion-content>
				</ion-modal>
				<ion-modal class="alert-settings" ref="alert" backdrop-dismiss="false" :is-open="isOpenAlert" @didDismiss="alertDismissed">
					<ion-content>
						<ion-toolbar>
							<ion-title> {{ confirmDeleteTitle }}</ion-title>
						</ion-toolbar>
						<div class="wrapper">
							<ion-label class="ion-text-wrap">{{ confirmDeleteMessage }}</ion-label>
							<br>
							<ion-label class="ion-text-wrap">{{	tablesData.currentPick.item.description }}</ion-label>
						</div>
						<div class="flex-row-center-container">
							<spot-button
								fill="outline"
								size="small"
								color="primary"
								:enabled="true"
								:expand="null"
								:text="undoText"
								@clicked="closeAlert(false)">
							</spot-button>
							<spot-button
								fill="outline"
								size="small"
								color="primary"
								:enabled="true"
								:expand="null"
								:text="confirmText"
								@clicked="closeAlert(true)">
							</spot-button>
						</div>
					</ion-content>
				</ion-modal>
			</div>
			<div id="debugArea" style="color:red;" v-if="settings.debug">
				<div>Test Area</div>
				<spot-button
					fill="outline"
					size="large"
					color="primary"
					:enabled="true"
					text="Simulate Scan"
					@clicked="onReadedBarcode('87196429','barcode')">
				</spot-button>
				<div v-for="field in fields" :key="field.id">
					{{ field.name }} = {{ field.value }}
				</div>
			</div>
		</ion-content>

		<spot-footer></spot-footer>
 </ion-page>
</template>

<style scoped>
	.modal-settings {
		--height: 80%;
		--width: 90%;
		--border-radius: 16px;
		--box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	}
	.alert-settings {
		--height: 50%;
		--width: 90%;
		--border-radius: 16px;
		--box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	}
	.wrapper {
		padding-left: 3vw;
		padding-right: 3vw;
	}
	ion-modal::part(backdrop) {
		background: var(--ion-color-medium);
		opacity: 1;
	}
	ion-modal ion-toolbar {
		--background: var(--ion-color-primary);
		--color: white;
	}
	ion-textarea {
		font-size: 12px;
		margin-top: 5px;
		margin-bottom: 0px;
		--padding-top: 0px;
		--padding-bottom: 0px;
		--inner-padding-top: 0px;
		--inner-padding-bottom: 0px;
		--min-height: 0px
	}
	.flex-row-center-container {
		margin-top: 0.2em;
		text-align: center;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	.no-pad {
		margin-top: 0px;
		margin-bottom: 0px;
		--padding-top: 0px;
		--padding-bottom: 0px;
		--inner-padding-top: 0px;
		--inner-padding-bottom: 0px;
		--min-height: 0px
	}
	*:deep(ion-checkbox) {
		margin-top: 0px;
		margin-bottom: 0px;
	}
</style>
<style>
	.empty-row  {
		--easy-table-body-row-background-color: var(--ion-color-danger); /*#f56c6c;*/
	}
	.partial-row  {
		--easy-table-body-row-background-color: var(--ion-color-warning); /*#ddc229;*/
	}
	.completed-row  {
		--easy-table-body-row-background-color: var(--ion-color-success); /*#67c23a;*/
	}
	.exceeded-row  {
		--easy-table-body-row-background-color: var(--ion-color-tertiary);/*#0d9de0;*/
	}
	.prominence-column {
		font-size: 20px;
	}
</style>

<script>
import { IonButton, IonContent, IonIcon, IonItem, IonLabel, IonModal, IonPage, IonProgressBar, IonTextarea, IonTitle, IonToolbar } from '@ionic/vue';
import { brush, duplicate, trash } from 'ionicons/icons';
import { defineComponent } from 'vue';
import SpotButton from '../components/SpotButton.vue';
import SpotCheckbox from '../components/SpotCheckbox.vue';
import SpotSelect from '../components/SpotSelect.vue';
import SpotSelectCustom from '../components/SpotSelectCustom.vue';
import SpotInput from '../components/SpotInput.vue';
import SpotFooter from "../components/SpotFooter.vue";
import SpotHeader from "../components/SpotHeader.vue";
import WebSocketReader from '../components/WebSocketReader.vue';
import { checkUserSession, confirmYesNo, settings, showMsgError, showMsgInfo, showMsgWarning, writeLog } from '../plugins/common.js';
import { createPick, createProjectRow, deleteProjectRow, deletePick, /*getItem,*/ getPick, getProject, searchAdditionalBarcode, searchItem, searchPicks, searchProjectRows, tablesData, updateProjectRow, updatePick, updateProject } from '../plugins/customDatabase.js';

	export default defineComponent({
		name: "ManageStoreSupply",
		props: {
			pageTitle: { type: String, default: 'Gestisci Prelievo' },
			noItemsText: { type: String, default: 'Nessun Articolo presente' },
			newItemText: { type: String, default: 'Nuovo Articolo' },
			editItemText: { type: String, default: 'Modifica Articolo'}, //'Modifica Articolo: '
			showItemText: { type: String, default: 'Visualizza Articolo'}, //'Visualizza Articolo: '
			suspendText: { type: String, default: 'Esci/Sospendi' },
			closeText: { type: String, default: 'Concludi' },
			undoText: { type: String, default: 'Annulla' },
			confirmText: { type: String, default: 'Conferma' },
			unselected: { type: String, default: 'Nessuno' },
			selectedText: { type: String, default: 'Selezionato' },
			deselectText: { type: String, default: 'annulla' },
			selectText: { type: String, default: 'seleziona' },
			messageSearchItemTitle: { type: String, default: 'Ricerca Articolo' },
			messageSearchItemNotFound: { type: String, default: 'Articolo non trovato!' + '<br>' + 'Codice letto: ' },
			messageTitleCheckTAG: { type: String, default: 'Controllo TAG' },
			warnMultipleTAG: { type: String, default: 'Letto più di un TAG. Avvicinarsi solo al TAG da leggere e riprovare' },
			warnInavlidTAG: { type: String, default: 'TAG letto solo in parte. Riprovare a leggere' },
			confirmDeleteTitle: { type: String, default: 'Conferma Cancellazione' },
			confirmDeleteMessage: { type: String, default: 'Confermi la cancellazione delle letture effettuate per l\'articolo corrente?' },
			messageTitleReader: { type: String, default: 'Lettura Barcode/TAG' },
			errorReadingReader: { type: String, default: 'Errore nella lettura del barcode/tag' },
			confirmExitTitle: { type: String, default: 'Conferma Uscita' },
			confirmExitMessage: { type: String, default: 'Sospensione! Se confermi l\'uscita, l\'ordine assumerà lo stato di sospeso e potrà essere riaperto successivamente. Confermi?' },
			confirmCloseMessage: { type: String, default: 'Evasione parziale! Confermi comunque la chiusura dell\'attività di impacchettamento?' }, // 'Confermi l\'uscita anche se non sono stati inseriti tutti i dati?'
			messageTitleInvalidQuantity: { type: String, default: "Quantità non valida" },
			warnExceededQuantity: { type: String, default: 'La Quantità inserita sommata alla Quantità rilevata in precedenza è superiore alla Quantità Prevista!' },
			warnNegativeQuantity: { type: String, default: 'Non è consentito inserire valori negativi nel campo Quantità!' },
			confirmItemChangeTitle: { type: String, default: 'Conferma Cambio Articolo' },
			confirmItemChangeMessage: { type: String, default: 'Confermi di voler sostituire l\'articolo corrente?' },
		},
		components: {
			IonPage,
			IonContent,
			IonProgressBar,
			IonItem,
			IonButton,
			IonIcon,
			IonLabel,
			IonModal,
			IonToolbar,
			IonTitle,
			IonTextarea,
			SpotHeader,
			SpotFooter,
			SpotInput,
			SpotCheckbox,
			SpotSelect,
			SpotSelectCustom,
			SpotButton,
			WebSocketReader,
		},
		data: () => ({
			isActive: null,
			field: {
				search: 0,
			},
			fields: [
				{ id: 0, name: 'search', text: 'Cerca: ', allignLabel: true, placeholder: 'Cerca Articolo', inputType: 'text', defaultValue: '', value: null, required: false, refField: null },
			],
			searchFields: ['item.code','item.description'],
			headers: [
				{ text: 'Descrizione', value: 'item.description', sortable: true },
				{ text: 'Azioni', value: 'actions', sortable: false }
				/*
				// { text: 'Ubicazione', value: 'location.name', sortable: true },
				{ text: 'Articolo', value: 'item.code', sortable: true },
				{ text: 'UM', value: 'item.measure_unit.name', sortable: false },
				{ text: 'Previsti', value: 'quantity', sortable: false },
				{ text: 'Rilevati', value: 'quantity_detected', sortable: false },
				// { text: 'Azioni', value: 'actions', sortable: false }
				*/
			],
			sortType: ['asc', 'asc'],
			sortBy: ['location.name','item.code'],
			itemContent: {
				// id: 0,
				// location: 1,
				code: 0, // 2,
				description: 1, // 3,
				measureUnit: 2,
				stock: 3,
				expectedQuantity: 4,
				quantity: 5,
			},
			itemContents: [
				// { id: 0, name: 'id', text: 'Id', enabled: false, placeholder: 'Inserisci ID', inputType: "number", validationState: null, defaultValue: null, value: null, required: true },
				// { id: 1, name: 'location', text: 'Ubicazione', enabled: false, placeholder: 'Inserisci Ubicazione', inputType: "text", validationState: null, defaultValue: null, value: null, required: true },
				// { id: 2, name: 'code', text: 'Codice', enabled: false, placeholder: 'Inserisci Codice', inputType: "text", validationState: null, defaultValue: null, value: null, required: true },
				{ id: 0, name: 'code', text: 'Articolo', enabled: false, allignLabel: false, placeholder: 'Inserisci Codice Articolo', inputType: 'text', defaultValue: null, value: null, required: true, furtherInfo: null, validationState: null, canAddItem: false, canRefresh: false, canUnlock: false },
				// { id: 3, name: 'description', text: 'Descrizione', enabled: false, placeholder: 'Inserisci Descrizione', inputType: "text", validationState: null, defaultValue: null, value: null, required: true },
				{ id: 1, name: 'description', text: 'Descrizione', enabled: false, allignLabel: false, placeholder: 'Inserisci Descrizione', inputType: 'area', defaultValue: null, value: null, required: true, furtherInfo: null, validationState: null, canAddItem: false, canRefresh: false, canUnlock: false },
				{ id: 2, name: 'measureUnit', text: 'Unità di Misura', enabled: false, allignLabel: true, placeholder: 'Inserisci Unità di misura', inputType: "text", validationState: null, defaultValue: null, value: null, required: true },
				{ id: 3, name: 'stock', text: 'Giacenza', enabled: false, allignLabel: true, placeholder: 'Inserisci Giacenza', inputType: 'number', defaultValue: null, value: null, required: true, furtherInfo: null, validationState: null, canAddItem: false, canRefresh: false, canUnlock: false },
				// { id: 4, name: 'expectedQuantity', text: 'Quantità Prevista', enabled: false, placeholder: 'Inserisci Quantità Prevista', inputType: "number", validationState: null, defaultValue: null, value: null, required: true },
				{ id: 4, name: 'expectedQuantity', text: 'Quantità Prevista', enabled: false, allignLabel: false, placeholder: 'Inserisci Quantità Prevista', inputType: 'number', defaultValue: null, value: null, required: true, furtherInfo: null, validationState: null, canAddItem: false, canRefresh: false, canUnlock: false },
				// { id: 5, name: 'quantity', text: 'Quantità Rilevata', enabled: true, placeholder: 'Inserisci Quantità Rilevata', inputType: "number", validationState: null, defaultValue: null, value: null, required: true },
				{ id: 5, name: 'quantity', text: 'Quantità', enabled: true, allignLabel: true, placeholder: 'Inserisci Quantità', inputType: 'number',  defaultValue: null, value: null, required: true, furtherInfo: null, validationState: null, canAddItem: false, canRefresh: false, canUnlock: false },
			],
			isOpenModal: false,
			isOpenAlert: false,
			modalTitle: null,
			modalReadOnly: null,
			actionDbCreate: null,
			isPicking: null,
			isRevision: null,
			isLocked: null,
			projectId: null,
			lastBarcode: null,
			lastTag: null,
			assignRemaingQuantity: false,
			allowExceededQuantity: true,
			allowNegativeQuantity: false,
			isStartedRowAction: false,
			duplicate,
			trash,
			brush,
			settings,
			checkUserSession,
			confirmYesNo,
			showMsgError,
			showMsgInfo,
			showMsgWarning,
			writeLog,
			// getItem,
			getPick,
			getProject,
			searchAdditionalBarcode,
			searchItem,
			searchPicks,
			searchProjectRows,
			createPick,
			createProjectRow,
			updatePick,
			updateProject,
			updateProjectRow,
			deletePick,
			deleteProjectRow,
			tablesData,
		}),
		async ionViewWillEnter() {
			this.getUrlParamsAndQuery();
			this.setDefault();
			await this.getAllTablesData();
			this.isActive = true;
		},
		ionViewWillLeave() {
			this.isOpenModal = false;
			this.isOpenAlert = false;
			this.isActive=false;
		},
		computed: {
			isEmptyRequiredFields() {
				let ret = false;
				this.itemContents.forEach(element => {if (element.required && !element.value && element.id > 0) ret=true});
				return ret;
			},
			/*
			isOrderClean() {
				let ret = true;
				this.tablesData.picks.forEach(element => {if (element.quantity_detected > 0) ret=false});
				return ret;
			},
			isOrderFinished() {
				let ret = true;
				this.tablesData.picks.forEach(element => {if (element.quantity != element.quantity_detected) ret=false});
				return ret;
			},
			*/
			checkQuantity() {
				let ret = true;
				if (!this.allowNegativeQuantity) {
					if ((parseFloat(this.itemContents[this.itemContent.quantity].value)) < parseFloat(0)) {
						this.showMsgWarning(this.messageTitleInvalidQuantity, this.warnNegativeQuantity);
						ret = false;
					}
				}
				if (ret == true && !this.allowExceededQuantity) {
					if ((parseFloat(this.tablesData.currentPick.quantity_detected) + parseFloat(this.itemContents[this.itemContent.quantity].value)) > parseFloat(this.tablesData.currentPick.quantity)) {
						this.showMsgWarning(this.messageTitleInvalidQuantity, this.warnExceededQuantity);
						ret = false;
					}
				}
				return ret;
			},
		},
		setup() {
			const bodyRowClassNameFunction = (item) => {
				if (parseFloat(item.quantity_detected) == parseFloat(item.quantity)) return 'completed-row'
				else if(parseFloat(item.quantity_detected) == parseFloat(0)) return 'empty-row'
				else if(parseFloat(item.quantity_detected) < parseFloat(item.quantity)) return 'partial-row'
				else return 'exceeded-row';
			}
			const bodyItemClassNameFunction = (column) => {
				if (column === 'quantity' || column === 'quantity_detected') return 'prominence-column';
				else return '';
			};
			return {
				bodyRowClassNameFunction,
				bodyItemClassNameFunction,
			};
		},
		methods: {
			// Settings & StartUp
			getUrlParamsAndQuery() {
				this.isPicking = (/true/i).test(this.$route.query.picking);
				this.isRevision = (/true/i).test(this.$route.query.revision);
				this.isLocked = (/true/i).test(this.$route.query.locked);
				this.projectId = this.$route.params.id;
			},
			setDefault() {
				this.fields.forEach(element => element.value = element.defaultValue);
				this.isOpenModal = false;
				this.isOpenAlert = false;
				this.modalTitle = null;
				this.modalReadOnly = null;
				this.actionDbCreate = null;
				this.lastBarcode = null;
				this.lastTag = null;
				this.isStartedRowAction = false;
			},
			getPlaceHolder(element) {
				return ((this.settings.showFieldsPlaceholder) ? (element.required ? element.placeholder : this.unselected ) : '')
			},
			getEnabled(element, defaultState) {
				let ret = false;
				// if(this.isActive == true) {
				if (defaultState != null) ret = defaultState;
				else {
					switch (element.id) {
						case this.itemContent.code:
							ret = !!this.itemContents[this.itemContent.code].value
							break;
						case this.itemContent.confirm:
							// ret = !!this.projectField.value && !! this.locationField.value
							ret = !this.isEmptyRequiredFields
							break;
					}
				}
				// }
				return ret
			},
			getOptions(element) {
				let ret = false;
				// if(this.isActive == true) {
				switch (element.id) {
					case this.itemContent.code:
						ret = this.tablesData.items
						break;
				}
				// }
				return ret
			},
			// Database
			async getAllTablesData() {
				await this.getProject(this.projectId);
				// this.tablesData.projectRows = [];
				this.tablesData.picks = [];
				// await this.searchProjectRows(this.projectId);
				await this.searchPicks(this.projectId);
			},
			async deleteExistingPick(pick) {
				let canDelete = false;
				await this.confirmYesNo(this.confirmDeleteTitle, this.confirmDeleteMessage)
					.then((data) => { canDelete = data })
				if (canDelete == true) {
					await this.deletePick(pick.id)
				}
			},
			async deleteExistingProjectRow(projectRow) {
				let canDelete = false;
				await this.confirmYesNo(this.confirmDeleteTitle, this.confirmDeleteMessage)
					.then((data) => { canDelete = data })
				if (canDelete == true) {
					await this.deleteProjectRow(projectRow.id)
				}
			},
			async deleteProjectRowsInPick(pick) {
				this.isStartedRowAction = true;
				let canDelete = false;
				await this.confirmYesNo(this.confirmDeleteTitle, this.confirmDeleteMessage)
				.then((data) => { canDelete = data })
				if (canDelete == true) {
					for (let i = 0; i < pick.project_rows.length; i++) {
						await this.deleteProjectRow(pick.project_rows[i].id, false)
					}
				}
				await this.getAllTablesData();
				this.isStartedRowAction = false;				
			},
			// Actions
			async onReadedBarcode(barcode,type) {
				this.lastBarcode = barcode;
				this.writeLog(barcode, type);
				await this.committedBarcode();
			},
			async onReadedTag(tag,type) {
				this.lastTag = tag;
				this.writeLog(tag, type);
				await this.committedTag();
			},
			onReaderError(data,error) {
				this.showMsgError(data, error);
				this.showMsgWarning(this.messageTitleReader, this.errorReadingReader);
			},
			changedSearch(value) {
				this.fields[this.field.search].value = value;
			},
			changedTextField(fieldId, value) {
				this.itemContents[fieldId].value = value;
				(!value || value.length == 0)? this.itemContents[fieldId].validationState = false : this.itemContents[fieldId].validationState = true
			},
			changedCheckboxField(fieldId, value) {
				this.itemContents[fieldId].value = value;
				(!value || value.length == 0)? this.itemContents[fieldId].validationState = false : this.itemContents[fieldId].validationState = true
			},
			assignedSelectField(fieldId, value) {
				this.itemContents[fieldId].value = value;
				(!value)? this.itemContents[fieldId].validationState = false : this.itemContents[fieldId].validationState = true
			},
			resetSelectField(fieldId) {
				this.itemContents[fieldId].value = null;
				this.itemContents[fieldId].validationState = false
			},
			prepareNewProjectRow() {
				let myRecord = {
					project_rows: {
						project_id: this.tablesData.currentPick.project_id, // this.projectId
						pick_id: this.tablesData.currentPick.id,
						location_id: this.tablesData.currentPick.location.id,
						item_id: this.tablesData.currentPick.item.id,
						quantity: this.itemContents[this.itemContent.quantity].value,
					}
				};
				this.writeLog(myRecord.project_rows);
				return myRecord;
			},
			prepareUpdateProjectRow() {
				let myRecord = {
					project_rows: {
						quantity: this.itemContents[this.itemContent.quantity].value,
					}
				};
				return myRecord;
			},
			prepareUpdatePickClosedFlag(isClosed) {
				let myRecord = {
					picks: {
						closed: isClosed,
					}
				};
				return myRecord;
			},
			updateItemContents(row) {
				Object.getOwnPropertyNames(row).forEach(rowField => {
					this.itemContents.forEach(pageElement => {
						if (rowField == pageElement.name) {
							pageElement.value = row[rowField];
						}
					});
				});
			},
			createNewCustomItemContents(itemRow) {
				this.itemContents[this.itemContent.code].value = itemRow.code
				this.itemContents[this.itemContent.description].value = itemRow.description
				this.itemContents[this.itemContent.measureUnit].value = itemRow.measure_unit.name
				this.itemContents[this.itemContent.stock].value = itemRow.quantity
				this.itemContents[this.itemContent.stock].furtherInfo = (itemRow.locked_quantity > 0)? ' (Disponibili: ' + (parseFloat(itemRow.quantity) - parseFloat(itemRow.locked_quantity)) + ')': null
				this.itemContents[this.itemContent.expectedQuantity].value = null
				this.itemContents[this.itemContent.expectedQuantity].furtherInfo = null
				this.itemContents[this.itemContent.quantity].value = null
				//this.itemContents[this.itemContent.quantity].furtherInfo = null
			},
			createCustomItemContents(pickRow) {
				this.itemContents[this.itemContent.code].value = pickRow.item.code
				this.itemContents[this.itemContent.description].value = pickRow.item.description
				this.itemContents[this.itemContent.measureUnit].value = pickRow.item.measure_unit.name
				this.itemContents[this.itemContent.stock].value = pickRow.item.quantity
				this.itemContents[this.itemContent.stock].furtherInfo = (pickRow.item.locked_quantity > 0)? ' (Disponibili: ' + (parseFloat(pickRow.item.quantity) - parseFloat(pickRow.item.locked_quantity)) + ')': null
				this.itemContents[this.itemContent.expectedQuantity].value = pickRow.quantity
				this.itemContents[this.itemContent.expectedQuantity].furtherInfo = (pickRow.quantity_detected > 0)? ' (Già inseriti: ' + parseFloat(pickRow.quantity_detected) + ')': null
				this.itemContents[this.itemContent.quantity].value = (!this.assignRemaingQuantity)? null : (parseFloat(pickRow.quantity) - parseFloat(pickRow.quantity_detected))
				//this.itemContents[this.itemContent.quantity].furtherInfo = ' / ' + (parseFloat(pickRow.quantity) - parseFloat(pickRow.quantity_detected))
			},
			updateCustomItemContents(row) {
				this.itemContents[this.itemContent.id].value = row.project_row.id
				this.itemContents[this.itemContent.location].value = row.location.name
				this.itemContents[this.itemContent.code].value = row.item.code
				this.itemContents[this.itemContent.description].value = row.item.description
				this.itemContents[this.itemContent.measureUnit].value = row.item.measure_unit.name
				this.itemContents[this.itemContent.expectedQuantity].value = parseInt(row.quantity)
				this.itemContents[this.itemContent.quantity].value = parseInt(row.quantity_detected)
			},
			async createNewPick(itemRow) {
				this.writeLog(itemRow);
				if (!itemRow) {
					this.itemContents.forEach(element => element.value = element.defaultValue);
				}
				else {
					this.tablesData.currentItem = itemRow;
					// await this.getItem(itemRow.id);
					this.createNewCustomItemContents(this.tablesData.currentPick);
				}
				this.itemContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.newItemText;
				this.actionDbCreate = true;
				this.openModal(false);
			},
			async tableItemClicked(tableRow) {
				if (!this.isPicking) {
					await this.editExistingPick(tableRow);
				}
				else {
					await this.createNewProjectRow(tableRow);
				}
			},
			async createNewProjectRow(tableRow) {
				if (!this.isStartedRowAction) {
					this.writeLog(tableRow);
					if (!tableRow) {
						this.itemContents.forEach(element => element.value = element.defaultValue);
					}
					else {
						this.tablesData.currentPick = tableRow;
						await this.getPick(tableRow.id);
						this.createCustomItemContents(this.tablesData.currentPick);
					}
					this.itemContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
					this.modalTitle = this.newItemText;
					this.actionDbCreate = true;
					this.openModal(false);
				}
			},
			async editExistingPick(pick) {
				this.updateCustomItemContents(pick);
				this.itemContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.editItemText // + pick.item.code;
				this.actionDbCreate = false;
				this.openModal(false);
			},
			async editExistingProjectRow(projectRow) {
				this.updateCustomItemContents(projectRow);
				this.itemContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.editItemText // + projectRow.item.code;
				this.actionDbCreate = false;
				this.openModal(false);
			},
			async showExistingPick(pick) {
				this.updateCustomItemContents(pick);
				this.itemContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.showItemText // + pick.item.code;
				this.actionDbCreate = false;
				this.openModal(true);
			},
			async showExistingProjectRow(projectRow) {
				this.updateCustomItemContents(projectRow);
				this.itemContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.showItemText // + projectRow.item.code;
				this.actionDbCreate = false;
				this.openModal(true);
			},
			openModal(isReadOnly) {
				this.modalReadOnly = isReadOnly;
				this.isOpenModal = true;
			},
			async closeModal(saveRecord) {
				let canCloseModal = true;
				if (saveRecord == true) {
					if (!this.isPicking) {
						if (this.actionDbCreate == true) {
							await this.createPick(this.prepareNewPick(), false)
						}
						else {
							await this.updatePick(this.itemContents[this.itemContent.id].value, this.prepareUpdatePickQuantity(), false)
						}
					}
					else {
						if (this.checkQuantity) {
							if (this.actionDbCreate == true) {
								await this.createProjectRow(this.prepareNewProjectRow(), false)
							}
							else {
								await this.updateProjectRow(this.itemContents[this.itemContent.id].value, this.prepareUpdateProjectRow(), false)
							}
							if ((parseFloat(this.tablesData.currentPick.quantity_detected) + parseFloat(this.itemContents[this.itemContent.quantity].value)) >= parseFloat(this.tablesData.currentPick.quantity)) {
								await this.updatePick(this.tablesData.currentPick.id, this.prepareUpdatePickClosedFlag(true), false)
							} else {
								await this.updatePick(this.tablesData.currentPick.id, this.prepareUpdatePickClosedFlag(false), false)
							}
						}
						else {
							canCloseModal = false;
						}
					}
				}
				if (canCloseModal) {
					await this.getAllTablesData();
					this.isOpenModal = false;
				}
			},
			async modalDismissed() {
				this.actionDbCreate = null;
			},
			async openAlert(tableRow) {
				this.isStartedRowAction = true;
				this.tablesData.currentPick = tableRow;
				await this.getPick(tableRow.id);
				this.isOpenAlert = true;
			},
			async closeAlert(deleteRecord) {
				if (deleteRecord == true) {
					for (let i = 0; i < this.tablesData.currentPick.project_rows.length; i++) {
						await this.deleteProjectRow(this.tablesData.currentPick.project_rows[i].id, false)
					}
					await this.getAllTablesData();
				}
				this.isStartedRowAction = false;
				this.isOpenAlert = false;
			},
			async alertDismissed() {
				this.actionDbCreate = null;
			},
			changedBarcode(value) {
				this.lastBarcode = value;
			},
			async searchBarcodeInProjectPicks() {
				let ret = null;
				this.tablesData.picks.some(element => {
					if (element.item.barcode == this.lastBarcode) {
						ret = element;
						return true;
					}
					if (element.item.additional_barcodes.length > 0) {
						element.item.additional_barcodes.some(subElement => {
							if (subElement.code == this.lastBarcode) {
								ret = element;
								return true;
							}
						})
					}
				});
				return ret;
			},
			async searchBarcodeInTables() {
				let ret = null;
				this.tablesData.currentItem = null;
				this.tablesData.currentAdditionalBarcode = null;
				await this.searchItem(null,this.lastBarcode);
				if (!this.tablesData.currentItem) {
					await this.searchAdditionalBarcode(this.lastBarcode);
					if (!this.tablesData.currentAdditionalBarcode) {
						this.showMsgWarning(this.messageSearchItemTitle, this.messageSearchItemNotFound + this.lastBarcode);
					}
					else {
						ret = this.tablesData.currentAdditionalBarcode.item;
					}
				}
				else {
					ret = this.tablesData.currentItem;
				}
				return ret;
			},
			async committedBarcode() {
				let foundElement = null;
				if (this.isOpenModal == false) {
					if (!this.isPicking) {
						foundElement = await this.searchBarcodeInProjectPicks();
						if (foundElement != null) {
							this.editExistingPick(foundElement)
						}
						else {
							foundElement = await this.searchBarcodeInTables();
							if (foundElement != null) {
								this.createNewPick(foundElement)
								/*
								this.tablesData.currentPick.item = foundElement;
								this.itemContents[this.itemContent.code].value = foundElement.code
								this.itemContents[this.itemContent.description].value = foundElement.description
								this.itemContents[this.itemContent.code].validationState = true
								this.itemContents[this.itemContent.stock].value = foundElement.quantity
								this.itemContents[this.itemContent.stock].furtherInfo = (foundElement.locked_quantity > 0)? ' (Disponibili: ' + (parseFloat(foundElement.quantity) - parseFloat(foundElement.locked_quantity)) + ')': null
								*/
							}
						}
					}
					else {
						foundElement = await this.searchBarcodeInProjectPicks();
						if (!foundElement) this.showMsgWarning(this.messageSearchItemTitle, this.messageSearchItemNotFound + this.lastBarcode);
						else await this.createNewProjectRow(foundElement); // editExistingPick(foundElement);
					}
				}
			},
			changedTag(value) {
				this.lastTag = value;
			},
			async committedTag() {
				let tagElements = this.lastTag.split('|');
				let foundElement = null;

				switch (tagElements.length) {
					case 0:
						this.lastTag = null;
						this.showMsgWarning(this.messageTitleCheckTAG,this.warnInavlidTAG);
						break;
					case 1:
						this.tablesData.picks.some(pick => {
							if (pick.item.code == this.lastTag) {
								foundElement = pick;
								return true;
							}
						});
						if (!foundElement) this.showMsgWarning(this.messageSearchItemTitle, this.messageSearchItemNotFound + this.lastTag);
						else this.editExistingPick(foundElement);
						break;
					default:
						this.lastTag = null;
						this.showMsgWarning(this.messageTitleCheckTAG,this.warnMultipleTAG);
						break;
				}
			},
			async createEmptyProjectRows() {
				let myRecord = {
					project_rows: {
						project_id: this.projectId,
						pick_id: null,
						location_id: null,
						item_id: null,
						quantity: 0,
					}
				};
				for (let i = 0; i < this.tablesData.picks.length; i++) {
					if (this.tablesData.picks[i].quantity_detected == 0) {
						myRecord.project_rows.pick_id = this.tablesData.picks[i].id;
						myRecord.project_rows.location_id = this.tablesData.picks[i].location.id,
						myRecord.project_rows.item_id = this.tablesData.picks[i].item.id,
						await this.createProjectRow(myRecord, false)
					}
				}
			},
			getProjectStatus() {
				let status = {
					closed: true,
					untouched: true,
				};
				this.tablesData.picks.forEach(element => {
					if (element.quantity_detected > 0) status.untouched = false;
					if (!this.allowExceededQuantity) {
						if (element.quantity != element.quantity_detected) status.closed = false;
					}
					else {
						if (element.quantity > element.quantity_detected) status.closed = false;
					}
				});
				return status;
			},
			async updateProjectFlags(storeFlag = null, preparedFlag = null, partialFlag = null, suspendedFlag = null) {
				let myRecord = {
					project: {
					}
				};
				if (storeFlag != null) myRecord.project['store'] = storeFlag
				if (preparedFlag != null) myRecord.project['prepared'] = preparedFlag
				if (partialFlag != null) myRecord.project['partial'] = partialFlag
				if (suspendedFlag != null) myRecord.project['suspended'] = suspendedFlag
				await this.updateProject(this.projectId, myRecord, false);
			},
			async closeSupply() {
				let canExit = true;
				let projectsStatus = this.getProjectStatus();

				if (!this.isPicking) {
					await this.updateProjectFlags(true, false, false, false);
				}
				else if (projectsStatus.closed == true) {
					await this.updateProjectFlags(this.isPicking, true, false, false);
				}
				else if (projectsStatus.untouched == true) {
					canExit = false;
				}
				else {
					await this.confirmYesNo(this.confirmExitTitle, this.confirmCloseMessage)
					.then((data) => { canExit = data })
					if (canExit == true) {
						await this.createEmptyProjectRows();
						await this.updateProjectFlags(this.isPicking, false, true, false);
					}
				}

				if (canExit == true) {
					this.$router.push("/store/selection" + "?picking=" + this.isPicking);
				}
			},
			async exitSupply() {
				let canExit = true;
				let projectStatus = this.getProjectStatus();

				if (!this.isPicking) {
					await this.updateProjectFlags(null, null, false, true);
				}
				else if (projectStatus.untouched == true) {
					await this.updateProjectFlags(null, null, null, false);
				}
				else {
					await this.confirmYesNo(this.confirmExitTitle, this.confirmExitMessage)
					.then((data) => { canExit = data })
					if (canExit == true) {
						await this.updateProjectFlags(null, null, false, true);
					}
				}

				if (canExit == true) {
					this.$router.push("/store/selection" + "?picking=" + this.isPicking);
				}
			}
		}
	});
</script>

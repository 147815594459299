import { alertController, toastController } from "@ionic/vue";
import { backendUrl, publicAccess } from "./variables.js";
import router from '../router';

export var settings = {
	showFieldsPlaceholder: true, //true = Show an information message on field when empty; false = Doesn't show an information message on field when empty
	nextFieldOnComboSelect: true, //true = If selection of a multiselect value do a goto next field; false = If selection doesn't anything else
	enableShipping: false, //true = Enable Output Items module, false disable it
	enableReceipt: false, //true = Enable Incoming Items module, false disable it
	enableInventory: false, //true = Enable Inventory module, false disable it
	enableSingleAssign: false, //true = Enable Single Assign module, false disable it
	enableMultipleAssign: false, //true = Enable Multiple Assign module, false disable it
	enableMovement: false, //true = Enable Warehouse Movement module, false disable it
	enablePickingList: false, //true = Enable Picking List module, false disable it
	printerManagement: true, //true = If application manage a printer; false = If application doesn't manage a printer
	test: false, //true = Enable Test Modules/Methods/Functions, false = disable it
	debug: false, //true = Show debug information, false = Doesn't show debug informations
}

export async function checkUserSession(response, colorType) {
	let myMessage = response.data.error;
	switch (response.status) {
		case 401:
			myMessage = "Sessione scaduta";
			break;
		case 409:
			myMessage = "Modifica in conflitto con altro operatore";
			break;
	}
	const toast = await toastController.create({
		message: myMessage,
		duration: 2000,
		color: colorType ? colorType : "dark",
	});
	
	if (response && response.status === 401) {
		if(publicAccess) router.push("/");
		else router.push("/login");
	}
	return toast.present();
}

export async function openToast(response, colorType) {
	const toast = await toastController.create({
		message: (response && response.status === 401) ? "Utente o password non validi" : response.data.error,
		duration: 2000,
		color: colorType ? colorType : "dark",
	});

	if (response && response.status === 401) {
		if(publicAccess) router.push("/");
		else router.push("/login");
	}
	return toast.present();
}

export async function showMsgInfo(title, message, timeout) {
	const alert = await alertController.create({
		header: title,
		message: message,
		cssClass: "alarm-info",
	});
	setTimeout(()=>alert.dismiss(),(timeout) ? timeout : 5000);
	await alert.present();
}

export async function showMsgWarning(title, message, timeout) {
	const alert = await alertController.create({
		header: title,
		message: message,
		cssClass: "alarm-warning",
	});
	setTimeout(()=>alert.dismiss(),(timeout) ? timeout : 5000);
	await alert.present();
}

export async function showMsgError(title, message) {
	const alert = await alertController.create({
		header: title,
		message: message,
		cssClass: "alarm-error",
		buttons: [
			{
				text: 'Continua',
				handler: () => {
					alert.dismiss(true);
					return false;
				}
			},
		]
	});
	
	await alert.present();
}

export async function insertValue(title, fieldName) {
	let inputValue = null;
	const alert = await alertController.create({
		header: title,
		inputs: [
			{
				name: 'inputData',
				type: 'text',
				value: null,
				placeholder: fieldName,
			},
		],
		buttons: [
			{
				text: 'OK',
				handler: () => {
					alert.dismiss(alert.processedInputs[0].value);
					//return false;
				}
			},
			{
				text: 'Annulla',
				handler: () => {
					alert.dismiss(null);
					return false;
				}
			}
		]
	});
	
	await alert.present();
	await alert.onDidDismiss().then((data) => {
		if (data && data.data) inputValue = data.data;
	})
	return inputValue;
}

export async function confirmYesNo(title, message) {
	let choice
	const alert = await alertController.create({
		header: title,
		message: message,
		buttons: [
			{
				text: 'SI',
				handler: () => {
					alert.dismiss(true);
					return false;
				}
			},
			{
				text: 'No',
				handler: () => {
					alert.dismiss(false);
					return false;
				}
			}
		]
	});
	
	await alert.present();
	await alert.onDidDismiss().then((data) => {
		choice = data.data;
	})
	return choice;
}

export function writeLog(...args) {
	if (this.settings.debug) console.log(...args);
}

export function getUserInfo() {
	if (localStorage.getItem("user")) {
		let myUser = JSON.parse(localStorage.getItem("user"));
		if (myUser.avatar_url) {
			myUser.avatar_url = `${backendUrl}${myUser.avatar_url}`
		}
		return myUser;
	}
}

export function truncate(text, length, clamp) {
	clamp = clamp || '...';
	var node = document.createElement('div');
	node.innerHTML = text;
	var content = node.textContent;
	return content.length > length ? content.slice(0, length) + clamp : content;
}

export function stripHTML(htmlString) {
	var div = document.createElement("DIV");
	div.innerHTML = htmlString;
	var text = div.textContent || div.innerText || "";
	return text;
}
